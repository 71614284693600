$primary: #2f80ed;
$primary-2: #42526e;
$radius-default: 24px;
$radius-button: 100px;

//cores tipo perfil
$color_google: #eb5757;
$color_linkedin: #2d9cdb;
$color_facebook: #2f80ed;

$color_button_login: #80297d;

$cor-p: $primary;
$cor-a: #219653;
$cor-e: #eb5757;
$cor-c: #f2994a;

// Padronização do projeto do gestão

// Cores

$color-buttons: #4d2c8e;
$color-text: #5f4a87;
$color-text-opacity: #8872b2;

//  Títulos h1

$font-family-h1: Roboto;
$font-weight-h1: bold;
$font-size-h1: 32px;

// Titulos h2

$font-family-h2: Roboto;
$font-weight-h2: bold;
$font-size-h2: 21px;

// Titulos h3

$font-family-h3: Roboto;
$font-weight-h3: normal;
$font-size-h3: 24px;

//Border Radius

$border-radius-card: 5px;
$border-radius-button: 100px;
$border-radius-input: 50px;

// Colors

$color-bg: #fff;
$color-main: #4d2c8e;
$color-inative: #dad8df;
$profiler-text: #8872b2;

// Background sistema

$color-background: #f4f0eb;

// Pontos de quebra do sistema

$small: 540px;
$media: 720px;
$big: 992px;
$extra-big: 1200px;

//Cores dos botões do sistema background

$button-primary: #80297d;
$button-primary-hover: #D51BED;

$button-secondary: #efedf3;
$button-secondary-hover: #e6e6e6;
$button-secondary-border: 1px solid #8872b2;

//Cores dos textos dos botões do sistema

$text-button-primary: #efedf3;
$text-button-secondary: #3D1A4F;
$text-button-confirmation: #e44c1a;

//Cor quando não tem foto

$color-bg-photo: #5f4a87;

//Modais

$color-button-modal: #5f4a87;
$color-title-modal: #3D1A4F;
$color-description-modal: #5f4a87;

//Select

$color-select-value: #8872b2;
