.try-again-page{
  header{
    display: block !important;
  
    @media(max-width: 500px){
      text-align: center;
    }
  }
  .brand {
    padding: 34px 38px 40px 77px;
    width: 260px;
    height: auto;
    @media screen and (max-width: 992px) {
      padding-left: 20px;
      padding-bottom: 0;
    }
  }
}

.try-again {
  width: 90%;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
  text-align: center;
  button {
    background-image: linear-gradient(191deg, #d61ced 0%, #80297d 100%);
    box-shadow: 0px 8px 16px -12px rgba(0, 0, 0, 0.75);
    border-radius: 10px 10px 10px 10px;
    border: 1px solid #8872b2;
    display: block;
    margin: 0 auto;
    color: #efedf3;
    width: 250px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-bottom: 87px;
    max-width: 80%;
    cursor: pointer;
  }
}